.login{
    padding: 5px 0;
    min-height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper{
        padding: 5px;
        width: 35%;
        background-color: #f1d79e;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
              width: 70%;
        }

        .heading{
            font-size: 2.5rem;
            font-weight: 500;
            text-align: center;
            margin-top: 40px;
        }

        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            width: 90%;

            .field{
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 5px;
                justify-content: center;

                input{
                    outline: none;
                    border: none;
                    height: 3rem;
                    font-size: 16px;
                }
            }
            button{
                font-size: 1.1rem;
                line-height: 1.25rem;
                padding: 0.8rem;
                width: 40%;
                border: none;
                background-color: var(--blue-card);
                color: whitesmoke;
                border-radius: 5px;
                font-family: "Reem Kufi", sans-serif;
                margin-bottom: 50px;
            }
        }
    }
}