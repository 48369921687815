@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@400;500;600;700&display=swap');


:root{
    --blue-card: #013869;
    --yellow-card: #f5af19;
}

/* ::-webkit-scrollbar {
    @apply w-3;
  }
  
  ::-webkit-scrollbar-track {
    @apply shadow rounded-lg bg-teal-100;
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-teal-400 rounded-lg hover:bg-teal-500 transition-all duration-200;
  } */

  ::-webkit-scrollbar{
    width: 0.75rem; 
  }

  ::-webkit-scrollbar-track {
    border-radius: 0.5rem; 
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
  }

  ::-webkit-scrollbar-thumb{
    background-color: var(--blue-card);
    transition-property: all; 
    transition-duration: 200ms; 
    border-radius: 0.5rem; 
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: #0a62af;
  }

body{
    margin: 0;
    font-family: "Anuphan", sans-serif;
    background-color: #f0f2f7;
}

.link{
    text-decoration: none;
    color: inherit;
}

.error{
    font-size: 12px;
    color: red;
}

input,
textarea {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 5px;
  color: #616161;
  border: 2px solid hsl(217, 91%, 60%, 30%);
  outline: none;
}