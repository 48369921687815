.home{
    display: flex;
    width: 100%;
    height: 90vh;
    align-items: center;
    justify-content: center;
    

    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 80%;

        @media (max-width: 900px) {
            flex-direction: column;
            width: 95%;
        }

        .homeContent{
            width: 50%;
            padding: 2rem;
            color: #31303b;
            display: flex;
            flex-direction: column;
            gap: 10px;
            @media (max-width: 900px) {
                width: 100%;
                align-items: center;
                text-align: center;
            }
            
            h1{
                font-size: 3rem;
                font-weight: bold;
            }

            p{
                font-size: 1.2rem;
                width: 80%;
            }

            .loginBtn{
                background-color: var(--yellow-card);
                padding: 8px 15px;
                text-decoration: none;
                color: inherit;
                border-radius: 5px;
                width: 40%;
                font-weight: bold;
                text-align: center;
            }

        }
        .imgContainer{
            width: 50%;
            img{
                width: 90%;
            }
        }
    }
}