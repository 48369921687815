.navbar{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .wrapper{
        width: 40%;
        display: flex;
        align-items: center;
        gap: 3rem;

        ul{
            list-style: none;
            display: flex;
            gap: 3rem;
        }

        .buttonWrapper{

            .loginLink{
                font-size: 0.875rem;
                line-height: 1.25rem;
                padding: 0.8rem;
                border: none;
                background-color: var(--blue-card);
                color: whitesmoke;
                border-radius: 5px;
                font-family: "Reem Kufi", sans-serif
            }

            button{
                font-size: 0.875rem;
                line-height: 1.25rem;
                font-weight: 500;
                padding: 0.8rem;
                border: none;
                background-color: #e2e3f8;
                color: var(--blue-card);
                border-radius: 5px;
                font-family: "Reem Kufi", sans-serif
            }
        }
    }
}